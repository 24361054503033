#work {
    background-color: #fff;
    padding-bottom: 50px;
}

.work-wrapper {
    width: 90%;
    margin: 0 auto;
}

.work-list {
    margin: 80px auto 0;
    display: flex;
    flex-direction: column;
}

.work-item {
    display: flex;
    align-items: center;
    margin-bottom: 100px;
}

.work-left {
    flex: 0.6;
    margin-right: 10px;
}

.work-right {
    flex: 0.4;
}

.work-left > img {
    width: 100%;
}

.work-title {
    font-size: 1.7em;
    margin-bottom: 1.7rem;
    font-weight: 700;
}

.work-desc {
    color: #666666;
    font-size: 1.1rem;
    line-height: 1.5;
}

.app-skills {
    padding: 0;
    margin-bottom: 20px;
}

.app-skill {
    display: inline-block;
    border-radius: 5px;
    padding: 5px 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 0.9em;
    font-weight: 600;
    background-color: rgb(239, 242, 245);
    color: black;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.app-btn {
    background-color: black;
    border: 1px solid white;
    border-radius: 5px;
    color: #ffffff;
    font-size: 0.9em;
    font-weight: 700;
    padding: 15px 20px;
    text-decoration: none;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    margin-right: 15px;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    margin-bottom: 10px;
    box-shadow: 0 5px 15px 0 rgb(0 0 0 / 15%);
}
  
.app-btn:hover {
    transform: translateY(-3px);
}