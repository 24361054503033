body {
  font-family: 'Source Sans Pro', sans-serif;
  width: 100%;
  margin: 0 auto;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

h2 {
  font-size: 4.5em;
}

h4 {
  font-size: 1.5em;
}
p {
  font-size: 1.1em;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

li {
  list-style-type: none;
}

.section-title {
  letter-spacing: 3px;
  text-align: center;
  padding-top: 90px;
  font-size: 2.3em;
}

.section-title-underline {
  width: 5%;
  margin: 0 auto;
  border: 2px solid #ffcc00;
  margin-top: 30px;
}

.section-desc {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 1.3em;
}


@media
only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (-o-min-device-pixel-ratio: 3/2),
only screen and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-device-pixel-ratio: 1.5){

  html,
  body{
    width:100%;
    overflow-x:hidden;
  }

}