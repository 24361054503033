header {
    background-image: url("../../top_background.jpg");
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    height: 100vh;
    color: white;
    padding: 0;
  }
  
  .header-main {
    z-index: 1;
    position: absolute;
      top: 50%;
      left: 40%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
  }
  
  .header-desc {
    margin-top: 10px;
    margin-bottom: 40px;
  }
  
  .header-btn {
    background-color: transparent;
    border: 1px solid white;
    border-radius: 5px;
    color: #ffffff;
    font-size: 17px;
    padding: 10px 25px 10px 25px;
    text-decoration: none;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    margin-right: 15px;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    cursor: pointer;
}
  
  .header-btn:hover {
    background-color: #ffffff;
    color: #ffcc00;
  }