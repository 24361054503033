@media only screen and (max-width: 1023px) {

    .header-nav {
        padding: 20px 60px 0;
    }

    .work-wrapper, .about-me-wrapper, .skill-wrapper, .contact-wrapper {
        width: 90%;
    }

    .section-title {
        padding-top: 60px;
    }

    .about-me-main {
        flex-direction: column;
    }

    .about-me-left, .about-me-right {
        padding: 0;
    }

    .about-me-left {
        margin-bottom: 50px;
    }

    .work-item {
        flex-direction: column;
    }

    .work-left > img {
        margin-bottom: 40px;
    }

    .work-right {
        text-align: center;
        width: 60%;
        margin: 0 auto;
    }

}

@media only screen and (max-width: 767px) {
    h2 {
        font-size: 4em;
    }

    h4 {
        font-size: 1.2em;
    }
    p {
        font-size: 1em;
        line-height: 1;
    }

    .header-nav {
        padding: 20px 40px 0;
    }

    .work-wrapper, .about-me-wrapper, .skill-wrapper, .contact-wrapper {
        width: 90%;
    }

    .work-item {
        margin-bottom: 120px;
    }

    .work-right {
        width: 80%;
    }

    #work {
        padding-bottom: 0;
    }

    #contact {
        padding-bottom: 50px;
    }

    .contact-main {
        flex-direction: column;
    }

    .contact-main-right {
        margin-top: 30px;
    }

    .contact-title {
        text-align: center;
        margin: 10px 0;
    }

    .contact-desc {
        text-align: center;
    }

    .contact-icons {
        margin-top: 30px;
    }

    .contact-main-left {
        width: 80%;
    }

    .contact-form {
        margin: 20px auto 0 auto;
    }

    .contact-btn {
        margin: 0 auto;
    }
}


@media only screen and (max-width: 480px) {
    h2 {
        font-size: 3.5em;
    }

    h4 {
        font-size: 1em;
    }
    p {
        font-size: 1em;
        line-height: 1;
    }

    .header-btn {
        font-size: 14px;
        padding: 8px 20px;
        margin-bottom: 10px;
    }

    .section-title {
        padding-top: 40px;
    }

    .about-me-main {
        margin-top: 60px;
    }

    .work-item {
        margin-bottom: 80px;
    }

    .work-left > img {
        margin-bottom: 30px;
    }

    .work-right {
        width: 95%;
    }

    .contact-main-left, .contact-main-right {
        width: 90%;
    }
}