.about-me {
    background-color: #FAFAFA;
}

.about-me-wrapper {
    width: 85%;
    margin: 0 auto;
    padding-bottom: 100px;
}
.about-me-main {
    display: flex;
    margin-top: 100px;
}

.about-me-left, .about-me-right {
    flex: 0.5;
    padding: 0 40px;
}

.about-me-desc {
    line-height: 1.5;
    color: #3b3b3b;
    margin-bottom: 20px;
}

.about-me-desc:last-child {
    margin-bottom: 0;
}

.about-me-desc > span {
    font-weight: 700;
}


.my-skills {
    padding: 0;
    margin-bottom: 20px;
}

.my-skill {
    display: inline-block;
    border-radius: 5px;
    padding: 10px 18px;
    margin-right: 15px;
    margin-bottom: 15px;
    font-size: 1em;
    font-weight: 600;
    background-color: rgba(153,153,153,.2);
    color: black;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
